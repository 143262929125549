@import "../../Global.scss";

.user-account-button{
  
  margin-right:30px;
  cursor: pointer;

  svg{
    width: 50px;
    fill:$header-color;
  }


}