.header{
  position: fixed;
  width: 100%;
  height: 100px;

  left:0;
  right:0;
  top:0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  // background: linear-gradient(135deg,
  //       hsl(280, 21%, 78%) 0%,
  //       #f2f2f7 40%,
  //       #f6f6f6 80%);

  background-color: #ffffff;
  border-bottom:1px solid rgb(213, 213, 231);
  z-index:5000;

  

  h1{
    text-transform: uppercase;
    color:#000;
    font-size: 28px;
    font-weight: 300;
    letter-spacing: 0.1em;
  }

        h2 {
          text-transform: uppercase;
          color: #000;
          font-size: 18px;
          font-weight: 300;
          letter-spacing: 0.1em;
          margin-right: 24px;
        }

  button{
    margin-right:40px;
    
    // height: 60px;
  }

    a {
      text-decoration: none;
      padding: 10px 30px 10px 0px;
      font-size: 140%;
      color: #4d58bd;
      font-weight: 400;
  
    }
    a:hover{
      color:orange;
    }

    @media screen and (max-device-width: 600px) {
        
    }

        .header-right {
          // position: relative;
          display: flex;
          align-items: center;
          height: 100%;
          width:unset;
          // width:30%;
          justify-content: flex-end;
    
          @media screen and (max-device-width: 600px) {
            display: none;
          }
        }

        .header-left{
          margin-left:80px;
          display: flex;
          justify-content: flex-start;
          // width: 30%;
        }

        .header-center{

        }

  

}


.logo{
  margin-left: 24px;
  cursor: pointer;
  width: 60px;
  height: 50px;
  img{
    width: 100%;
    
  }
  fill:#4d58bd;

    @media screen and (max-device-width: 600px) {
      width:60px;
    }
}

.form-holder{
  // margin-top: 40px;
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
    @media screen and (max-device-width: 600px) {
        display: none;
      }
  // top:0;
  // margin: 0;
    // height: ;
}

.code-form {
  // top:0;
  
  height: 140px;
  width:180px;
  margin: 0;
  
  display: flex;
  align-items: center;

}

.input-field {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-right: -6px;
  font-size: 12px;
  height: 40px;
  width: 140px;
}

.submit-button {
  padding: 10px 15px;
  border: none;
  border-radius: 0 8px 8px 0;
  height: 40px;
  color: white;
  cursor: pointer;
  // font-size: 16px;
}

.submit-button:hover {
  // background-color: darkblue;
}

