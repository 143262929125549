@import "../../Global.scss";
.insight{
  


  .answers {
    

  }

  .environment{
    justify-content: flex-start;

    .label{
      justify-content: flex-start;
      text-align: left;
    }
  }

  .question{
   
    justify-content: left !important;
    font-size: 18px;
    line-height: 1.2;
    text-align: left;
    padding: 12px 20px;
    background-color: $background-light;
    border-radius: 10px;
    color: $header-color;
  }

  .thumbnail{
    width:50px;
    height:50px;
    border-radius: 25px;
    margin-right: 12px;
  }




}

