$button-color: #53BE77;
$alternate-button-color: #2388A8;


//headers
$header-color: #517682;
$header-alternate-color: #53BE77;

$background: #ddf4ec;
$background-light: #f4fdfd;
$background-highlight: #cae1e1;


$toggle-on-color: #3F5A63;
