.dashboard{
    .content {
        margin-top: 200px;
        // margin-left: 10%;
        // margin-right: 10%;

        h3{
          margin: 24px 10%;
          font-size: 24px;
          font-weight: 400;
          color:black;
        }
      }

   
}