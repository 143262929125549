
// @import 'Global';

// * {
//   box-sizing: border-box;
// }

// html{


//       margin:0;
//       padding:0;

// }

// body {

// font-family: "mr-eaves-modern", Open Sans;
 
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;

//   margin: 0;
//     padding: 0;
// }

// h1,h2,h3,h4,h5,p{
//   color: $header-color;
// }

// h1{
//   font-weight: 300;
// }

// h2{
//   font-size: 36px;
// }

// h3{
//   font-size: 30px;
// }

// h4{
//   font-size: 24px;
// }
// p{
//   font-size: 20px;
// }

// code {
//   font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
//     monospace;
// }


button {
  color: white;
  font-weight: 600;
  font-size: 14px;
  background-color: rgb(215, 65, 11);
  padding: 8px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  height:50px;

}

// button.alternate{
//   background-color: rgb(60, 64, 174);
// }
