.contact-form {
  display: flex;
  flex-direction: column;
  width: 400px;
  // margin: auto;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  label {
    margin-top: 10px;
    margin-bottom: 5px;
    font-weight: bold;
  }

  input[type="email"],
    input[type="name"],
  textarea {
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }


  p {
    margin-top: 20px;
  }
}