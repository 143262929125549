.demo{
  margin-top:100px;
  display: flex;
  div{
    width: 50%;
  }
  padding: 0 10%;

     @media screen and (max-device-width: 600px) {
      flex-direction: column;
      div{
        width: 100%;
      }
      // padding:0;
     }
}