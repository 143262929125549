@import "../Global.scss";



.date-range-picker{
  display: flex;
  align-items: center;
  justify-content: center;
  width:100%;
  // height:100%;
flex-wrap: wrap;

  input{
    width:100px;
    padding: 2px 8px;
    // border-radius: px;
    border: none;
    background-color: $background;
    cursor: pointer;
    margin-right:12px;
  }

 
  
    h2 {
      font-size: 18px;
    }
  
    h3 {
      font-size: 14px;
      margin-right:12px;
      margin-left:20px;
      
    }

  .row {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

  .range-picker {
      display: flex;
      flex-direction: row;
      align-items: center;
      // justify-content: space-around;
      // width: 100%;
      // margin-bottom: 40px;
      // width: 500px;
      
      // justify-content: center;
  
      button {
        width: auto;

        // padding: 8px 10px;
      }

     
  
    }

  .react-datepicker-wrapper{
    width: 100px;
  }


}

