.section{
  width:100%;
  min-height: 200px;
  // padding:50px 0;
  background-color: white;
  position: relative;
  display: flex;
  justify-content:space-evenly;
  align-items: top;
  text-align: left; 
  flex-wrap: wrap;

}

.banner{
  align-items: center;

 
}

.splash{
  // min-width: 500px;
  // max-width: 600px;
  // padding:10px;
  width:100%;
  color: rgb(60, 64, 174);
  h1 {
    font-size: 60px;
    font-weight: 400;
  }

  .tagline{
    position: absolute;
    height:100%;
    right:0;
    width:50%;
    top:0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-right:80px;
    h1,h4{
      color:white;
    }
    h1{
      font-size: 4em;
      line-height: 1.1em;
    }
    h4{
      font-size: 2em;
      font-weight: 400;
    }

         @media screen and (max-device-width: 600px) {
h1,h4{
  color:unset;
}
         }
    
  }

     @media screen and (max-device-width: 600px) {
       min-width: 100%;
       
      //  padding:10px;
       text-align: center;
       h1{
        font-size: 40px;
        
       }
        .tagline{
       position: relative;
       transform: none;
       padding:0;
   
    width:100%;

  }
     }
     
}

.form{
  margin-top:-40px;
}

