@import "../../Global.scss";

.event {
  display: flex;
  flex-direction: column;

  justify-content: space-between;
  align-items: center;
  width: 80%;
  border: 1px solid #eee;
  background-color: white;
  border-radius: 10px;
  padding: 8px 12px;
  margin: 8px 10%;

  h1 {
    font-size: 16px;
    font-weight: 400;
    text-transform: uppercase;
    color: black;

  }

  h2 {
    margin: 4px 0;
    font-size: 18px;
    line-height: 1;
    text-align: left;
    color: black;
    font-weight: 400;
    // text-transform: capitalize;
  }

  h4 {
    font-size: 12px;
  }

  h1,
  h4 {
    margin: 12px 0;
    margin-right: 12px;
  }

  .row {
    display: flex;
    // align-items: center;
    align-items: baseline;

    &.top {
      align-items: baseline;
    }

    &.wrap {
      flex-wrap: wrap;
    }
  }

  .first-row {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .label {
    // background-color: black;
    position: relative;
    max-height: 120px;
    overflow: hidden;
  }


  .thumbnail {
    width: 40px;
    /* Adjust as needed */
    height: 40px;
    /* Adjust as needed */
    object-fit: cover;
    /* Ensures the image covers the area without stretching */
    border-radius: 100%;
    /* Creates the circular shape */
    margin-right: 20px;
  }
}

.assessment {
  .chat {
    border-radius: 20px;
    width: 100%;
    background-color: lightblue;
    padding: 8px 18px 18px;

    margin: 8px 0;
    margin-right: 12px;
    font-size: 18px;
    line-height: 1.2;

   

    &.question {
      margin-top: 16px;
      background-color: $background-light;



    }


    &.student {
      // background-color: rgb(178, 235, 224);

    }

    &.feedback {
      background-color: $background-light;

    }

    &.summary {
      background-color: white;
      border: 1px solid $header-color;
    }

    h2 {
      margin: 0 0 4pxpx 0;
      text-transform: uppercase;
      // color: #333;
      color: $header-color;
      font-size: 13px;
      
    }
  }

  .topic {
    background-color: darkblue;
    color: white;
    border-radius: 12px;
    margin: 4px;
    padding: 4px 18px;


  }

  // h4 {
  //   font-size: 18px;
  //   margin: 6px;
  // }

  .row {
    display: flex;
    width: 100%;

    &.wrap {
      flex-wrap: wrap;
    }
  }
}