@import "../../Global.scss";

.filters{
  position:fixed;
  width:calc(95% - 100px);
  margin:auto;
  left:100px;
  right:0;
  // height:70px;
  
  top: 100px;
  margin:auto;
  padding:0;
  // border:1px solid #ccc;
  border-top: none;
  background-color: $background-highlight;
  border-radius: 0 0 30px 30px;
  z-index: 100;
  // box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1);

  // h3{
  //   font-size: ;
  // }
  


}