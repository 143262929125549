@import "../../Global.scss";

.session-list-item{
  .name{
    // text-align: center;
    p, h3{
      margin:0;
    }
    p{
      
    }

    max-width: 200px;
    overflow: hidden;
    // padding-top:24px;
    // display: flex;
    // flex-direction: column;
    // justify-content: center;
  }

  .date{
    font-size: 14px;
    color:#252525;
  }

    


}