.score {

  width: 50px !important;
  height: 50px !important;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 18px;
  // color:white;
}