@import "../Global.scss";

.top-bar {
  position: fixed;
  top: 100px;
  left:0;
  right:0;
  width:90%;
  background-color: $background; /* Example color */
  color: white;
  padding: 10px;
  transition: opacity 0.5s ease, visibility 0.5s;
  opacity: 1;
  z-index: 1000;
  visibility: visible;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;

  margin:auto;

  border-radius: 0 0 30px 30px;

  &.dashboard{
    width: calc(95% - 100px);
    margin-left:130px;
  }
  

  h2{
    padding: 0;
    margin:0;
    margin-right:12px;
    // color: #fb613f;
    font-size: 24px;
  }

  h4{
    padding: 4px;
    margin: 0;
    // color: rgb(230, 219, 255);
    font-size: 18px;
  }

  .row{
    display: flex;
    margin: 0 10px;
  }
}

.hidden {
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
}

.visible {
  opacity: 1;
  visibility: visible;
}