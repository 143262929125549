@import '../Global';

.session{
  // width: 80%;
  // margin: auto;
  margin-top:180px;
  .content{
    width:100%;
    margin:auto;
  }
  
  
  .top-row {
    
    div{
      padding: 40px 10px;
      max-width: 250px;
    }
    h2{
      font-size: 72px;
      font-weight: 400;
      margin: 0;
      margin-bottom: 10px;
      
      color:$header-alternate-color;
    }

    h4{
      margin:0;
      font-size: 24px;
      font-weight: 400;
    }
    justify-content: space-around;
    display: flex;
    align-items: center;
    text-align: center;
  }
}

/* The switch container */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide the default checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $header-color;
  transition: .4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
  
}

input:checked+.slider {
  background-color: $toggle-on-color;
}

input:checked+.slider:before {
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.toggle{
  display: flex;
  // justify-content: flex-end;
  margin-top: -4px;
  align-items: center;
  h4{
    margin-left:12px;
  }

  .switch{
    margin-right: 8px;
   
  }

 
}
