.newsbar{
  width: 100%;
  background-color: #2950c5;
  margin-top:100px;
  padding: 0px 20px;
  text-align: left;
  display: flex;
  justify-content: center;
  align-items: center;
  h2{
    font-size: 28px;
    font-weight: 400;
    color: white;
  }

  button{
    min-width: 140px;
    margin-left:20px;
  }

  @media screen and (max-device-width: 600px) {
    flex-wrap: wrap;
    padding-bottom: 20px;
    text-align: center;
    h2{
      font-size: 22px;
    }
      
    
  }
}