@import "../Global.scss";

.sort-toggle-button{
    background-color: transparent;
    color: #ccc ;
    padding: 0 4px;
    font-weight: 300;
    font-size: 24px;
    border: none;

    &:hover{
      color:$header-color;
    }

    &.active{
      color: $header-color;
    }
    
 
}