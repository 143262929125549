.experiences {
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

h2 {
  color: #333;
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: bold;
}

.experience-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  margin-bottom: 10px;
  transition: box-shadow 0.2s ease;
  
  &:hover {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }

  span {
    flex-grow: 1;
    font-weight: 500;
    color: #555;
  }
}

.view-link {
  color: #007bff;
  text-decoration: none;
  margin-right: 10px;
  transition: color 0.2s ease;

  &:hover {
    color: #0056b3;
  }
}

.copy-button {
  background-color: #4caf50;
  border: none;
  border-radius: 4px;
  color: white;
  padding: 6px 12px;
  cursor: pointer;
  transition: background-color 0.2s ease;
  margin-left: 10px;

  &:hover {
    background-color: #388e3c;
  }

  &:active {
    background-color: #2e7d32;
  }

  &.copied {
    background-color: #ff9800;
  }
}

// button {
//   background-color: #007bff;
//   color: white;
//   border: none;
//   border-radius: 4px;
//   padding: 10px 16px;
//   cursor: pointer;
//   margin-top: 20px;
//   transition: background-color 0.2s ease;

//   &:hover {
//     background-color: #0056b3;
//   }

//   &:active {
//     background-color: #004494;
//   }
// }

.dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
  z-index: 1000;
}

.dialog-content {
  display: flex;
  flex-direction: column;

  h3 {
    margin-bottom: 16px;
    color: #333;
  }

  input {
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }

  button {
    margin-top: 10px;
  }

  .cancel-button {
    background-color: #dc3545;
    
    &:hover {
      background-color: #c82333;
    }
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}
