.about{
  width:300px;
  img{
    border-radius: 30px;
    width:100%;
    margin:auto;
    left:0;
    right:0;
    // transform: translateX(25%);
    object-fit: cover;
    height:200px;
    
  }
}