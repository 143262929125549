.list-item{
  position: relative;
  width:80%;
  margin: 18px 10%;
  border: 1px solid rgb(229, 229, 229);
  padding: 12px 24px;
  border-radius: 10px;
  // padding-bottom:24px;

  // box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    transition: 0.3s;
    z-index: 0;
}