@import "../Global.scss";

.analytics-header {

  height:60px;
  h2 {
    font-size: 36px;
    font-weight: 400;
    margin: 0;
    color: $header-alternate-color;
  }

  width:15%;
  top:100px;
  right:80px;

  // padding: 0 50px;
  // left:0;
  // right:0;
  margin:0;
  position:fixed;
  // border-bottom: 1px solid #cfcfcf;

  justify-content: flex-end;
  display: flex;
  align-items: center;
  text-align: center;
  // background-color: white;

  z-index: 1000;

  &.dashboard{
    margin-left:100px;
  }
}