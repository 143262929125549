@import "../../Global.scss";


.grid-header, .grid-row{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: auto;

  margin-bottom: 12px;
    // border-bottom: 1px solid #eee;
    padding: 12px 15%;
}

.grid-header{
  position:sticky;
  top:160px;
  text-transform: uppercase;
 
    background-color: white;
    border-bottom: 1px solid #eee;
    border-top: 1px solid #eee;
}

.grid-row {
 
 

  h2 {
    font-size: 18px;
    // margin:0;
  }

  h3 {
    margin: 0;
    margin-right: 10px;
  }

 
  &:hover{
    background-color: $background-highlight;
  }

}

.grid-column {

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;

  &.left{
    justify-content: flex-start;
  }

  &.right{
    justify-content: flex-end;
  }

  &.narrow {
    width: 50px;
    height: 50px;

  }

    &.slim {
      width: 100px;

  
    }

  &.medium {
    width: 220px;

  }

  &.wide {
    width: 60%;
  }

}

.statistic{
  width: 25%;
  height: 160px;
  background-color: $background-light;
  border-radius: 10px;
  flex-direction: column;
  justify-content: center;
  // padding-bottom:12px;
  h1,h2,h3{
    margin:0px !important;
    color:black;
  }

  h1{
    font-weight: 400;
    font-size: 50px;
    line-height: 1;
    margin:8px !important;

  }

  h2{
    font-weight: 300;
    text-transform: uppercase;
        letter-spacing: 0.04em;
        font-size: 21px;
  }

  h3{
    font-weight: 400;
    font-size: 18px !important;
  }

  
}

 button.dashboard {
   margin: 10px;
   border-radius: 20px;

   height: auto;
   background-color: white;
   border: 1px solid $header-color;
   color: $header-color;
   text-transform: uppercase;
   width: 120px;
   font-size: 13px;

   &.selected,
   &:hover {
     background-color: $header-color;
     color: white;
   }
 }
